<template>
  <div class="add">
    <!-- <div class="main-Title bgff">
      <h2>Add</h2>
    </div> -->
    <div class="mainBox">
      <div class="bgff pl20 pr20 pt10 pb20">
        <el-form :model="addData" ref="addData" :rules="rules" size="mini" label-width="90px">
          <div class="infotitle">
            <h3><label>-- Offer Info --</label></h3>
          </div>
          <el-row>
            <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="5">
              <el-form-item label="OfferName" prop="offerName">
                <el-input v-model="addData.offerName" placeholder="offerName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="5">
              <el-form-item label="Category" label-width="80px">
                <el-input
                  v-model="addData.category"
                  placeholder="category,category,eg.games,install...."
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="5">
              <el-form-item label="PrevewLink" prop="previewLink" label-width="100px">
                <el-input v-model="addData.previewLink" placeholder="previewLink"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="5">
              <el-form-item label="TrafficAllowed" label-width="110px">
                <el-input
                  v-model="addData.trafficAllowed"
                  placeholder="trafficAllowed,eg.A,B,C"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
              <el-form-item label="TrafficForbidden" label-width="120px">
                <el-input
                  v-model="addData.trafficForbidden"
                  placeholder="trafficForbidden,eg.A,B,C"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="5">
              <el-form-item label="ConversionFlow" label-width="120px">
                <el-input
                  v-model="addData.conversionFlow"
                  placeholder="trafficForbidden,eg.A,B,C"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="5">
              <el-form-item label="Incentive" label-width="80px">
                <el-select v-model="addData.incentive" class="w100">
                  <el-option
                    v-for="item in incentiveOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="5">
              <el-form-item label="OsMinVersion" label-width="110px">
                <el-input
                  v-model="addData.osMinVersion"
                  placeholder="osMinVersion,eg. 3.2.0"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="5" :xl="5">
              <el-form-item label="important" label-width="80px">
                <el-select v-model="addData.important" class="w100">
                  <el-option
                    v-for="item in importantOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="9" :xl="9">
              <el-form-item label="OfferDescription" label-width="130px" class="mb0">
                <el-input type="textarea" :rows="2" v-model="addData.description"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-form-item label="ProductType">
        <el-select v-model="addData.productType">
          <el-option
            v-for="item in productTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item> -->
          <!-- <el-form-item label="SettleEventType" label-width="120px">
        <el-input
          v-model="addData.settleEventType"
          placeholder="settleEventType,eg. register"
        ></el-input>
      </el-form-item> -->
          <div class="infotitle">
            <h3><label>-- Payout Info --</label></h3>
          </div>
          <div v-for="(payoutInfoItem, index) in addData.payoutInfo" :key="index">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
                <el-form-item label="Currency" prop="currency" label-width="70px">
                  <el-select
                    v-model="payoutInfoItem.currency"
                    placeholder="currency,eg. USD"
                    class="w100"
                  >
                    <el-option
                      v-for="item in currencyOption"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
                <el-form-item label="PayoutType" v-if="index === 0">
                  <el-select
                    v-model="payoutInfoItem.payoutType"
                    @change="SelectPayout"
                    class="w100"
                  >
                    <el-option
                      v-for="item in payoutOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
                <el-form-item label="EventName" prop="eventName" v-if="index > 0">
                  <el-input v-model="payoutInfoItem.eventName" placeholder=""></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
                <el-form-item
                  label="Payout"
                  label-width="70px"
                  :prop="`payoutInfo[${index}].payout`"
                  :rules="[{ required: true, message: 'please add payout', trigger: 'blur' }]"
                >
                  <el-input
                    v-model="payoutInfoItem.payout"
                    placeholder="payout,eg. 3.2"
                    @keyup.native="UpNumber"
                    @keydown.native="UpNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
                <!-- 由于多层嵌套关系，采用内嵌式rules -->
                <el-form-item
                  label="Revenue"
                  label-width="70px"
                  :prop="`payoutInfo[${index}].revenue`"
                  :rules="[{ required: true, message: 'please add revenue', trigger: 'blur' }]"
                >
                  <el-input
                    v-model="payoutInfoItem.revenue"
                    placeholder="revenue"
                    @keyup.native="UpNumber"
                    @keydown.native="UpNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
                <el-form-item label="CapDaily" label-width="70px">
                  <el-input
                    v-model="payoutInfoItem.capDaily"
                    placeholder="capDaily,eg. 100"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="4" :lg="4" :xl="4">
                <el-button
                  type="primary"
                  v-if="index === 0"
                  icon="el-icon-plus"
                  circle
                  size="mini"
                  @click="plusPayoutInfo()"
                ></el-button>
                <el-button
                  type="danger"
                  v-if="index > 0"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  @click="delPayoutInfo(index)"
                ></el-button>
              </el-col>
            </el-row>
          </div>
          <!-- <el-form-item label="RemainingCapDaily" label-width="130px">
        <el-input
          v-model="addData.remainingCapDaily"
          placeholder="remainingCapDaily,eg. 100"
        ></el-input>
      </el-form-item> -->

          <div class="infotitle">
            <h3><label>-- Setting Info --</label></h3>
          </div>
          <el-row>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Platforms" prop="platforms" label-width="70px">
                <el-select v-model="addData.platforms" @change="platformsChange" class="w100">
                  <el-option
                    v-for="item in platformsOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Countries" prop="countries">
                <el-input
                  v-model="addData.countries"
                  placeholder="countries,eg.HK,US,CN.."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Regions" prop="regions" label-width="70px">
                <el-input v-model="addData.regions" placeholder="regions"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Cities" label-width="60px">
                <el-input
                  v-model="addData.citys"
                  placeholder="citys,eg.Beijing,London,Tokyo.."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="DownloadType" label-width="100px">
                <el-select v-model="addData.downloadType" class="w100">
                  <el-option
                    v-for="item in downloadTypeOption"
                    :key="item.vlaue"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label="Carriers" prop="carriers" label-width="70px">
                <el-input v-model="addData.carriers" placeholder="carriers,eg.Banana,Apple..." />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label-width="115px" label="Control Type" prop="type">
                <el-select v-model="addData.type" class="w100">
                  <el-option
                    v-for="item in typeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="infotitle">
            <h3><label>-- Source Info --</label></h3>
          </div>
          <el-row>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item label="SourceId" prop="sourceId" label-width="70px" cla>
                <el-select v-model="addData.sourceId" :loading="loading" filterable class="w100">
                  <el-option
                    v-for="item in sourceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label-width="120px" label="SourceOfferId" prop="sourceOfferId">
                <el-input v-model="addData.sourceOfferId" placeholder="sourceOfferId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10">
              <el-form-item
                label-width="150px"
                label="SourceTrackingLink"
                prop="sourceTrackingLink"
              >
                <el-input
                  v-model="addData.sourceTrackingLink"
                  placeholder="sourceTrackingLink,should configure click_id,subid,gaid,idfa"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item
                label-width="140px"
                label="impressionTracking"
                prop="impressionTracking"
              >
                <el-input
                  v-model="addData.impressionTracking"
                  placeholder="impression Tracking"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item label-width="130px" label="impressionOpen" prop="impressionOpen">
                <el-switch
                  v-model="addData.impressionOpen"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10">
              <el-form-item label="impression ratio" prop="impressionRatio" label-width="130px">
                <el-input
                  :disabled="!addData.impressionOpen"
                  v-model="addData.impressionRatio"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-form-item label="ReportLink">
        <el-input
          v-model="addData.reportLink"
          placeholder="直客广告主提供的上报链接，需先配置好"
        ></el-input>
      </el-form-item> -->
          <!-- souceTracking -->
          <!-- <el-form-item label="SouceTracking">
        <el-input v-model="addData.sourceTracking" placeholder="souceTracking here..."></el-input>
      </el-form-item>
      <el-form-item>
        <el-tag type="warning">屏蔽跳转才需要填写SourceTracking</el-tag>
      </el-form-item> -->
          <div v-show="isCPI">
            <div class="infotitle">
              <h3>
                <label>
                  -- Prod Info --
                  <span style="color:red;">(must import first)</span>
                </label>
              </h3>
            </div>
            <el-row>
              <el-col :xs="24" :md="8">
                <el-form-item label="Pkg Name" prop="prod">
                  <el-input
                    v-model.trim="addData.prod"
                    placeholder="pkgName,eg. com.test.sl.ss / 300225522"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-form-item>
          <el-button type="primary" @click="checkPkgExist">Check pkg Exist</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="importProdClick" style="margin-left:20px;"
            >Import Prod</el-button
          >
        </el-form-item> -->
          </div>
          <!-- <el-form-item label="Isdynamic">
        <el-select v-model="addData.showDynamic">
          <el-option
            v-for="item in showDynamicOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="ShowLink">
        <el-input v-model="addData.showLink" placeholder="link,eg:A,B,C"></el-input>
      </el-form-item>
      <el-form-item label="InnerText">
        <el-input v-model="addData.showInnerText" placeholder="innerText"></el-input>
      </el-form-item>
      <el-form-item label="AdType">
        <el-select v-model="addData.showAdType">
          <el-option
            v-for="item in showAdTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Close">
        <el-select v-model="addData.showClose">
          <el-option
            v-for="item in showCloseOption"
            :key="item.value"
            :label="item.laebl"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item> -->
          <div>
            <div class="infotitle">
              <h3>
                <label>
                  -- Sub rule info --
                  <!-- <span style="color:red;">(must import first)</span> -->
                </label>
              </h3>
            </div>
            <el-row>
              <!--              <el-col :xs="24" :md="6">-->
              <!--                <el-form-item label="offer manager" prop="accountId" label-width="110px">-->
              <!--                  <el-select-->
              <!--                    v-model="addData.accountId"-->
              <!--                    placeholder="Please select offer manager"-->
              <!--                    class="w100"-->
              <!--                  >-->
              <!--                    <el-option-->
              <!--                      v-for="item in option.manager"-->
              <!--                      :key="item.accountId"-->
              <!--                      :label="item.username"-->
              <!--                      :value="item.accountId"-->
              <!--                    ></el-option>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :xs="24" :md="6">
                <el-form-item label="sub rule" prop="ruleId">
                  <el-select
                    v-model="addData.ruleId"
                    placeholder="Please select sub rule"
                    class="w100"
                  >
                    <el-option
                      v-for="item in option.rule"
                      sss
                      :key="item.ruleId"
                      :label="item.ruleName"
                      :value="item.ruleId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div>
            <div class="infotitle">
              <h3>
                <label>
                  -- Creativies info --
                </label>
              </h3>
            </div>
            <el-form-item label-width="0px">
              <!-- 图片上传 -->
              <el-upload
                action="/api/creatives/saveImages"
                list-type="picture-card"
                :multiple="true"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </div>
          <el-row>
            <el-col :span="24">
              <el-button type="primary" size="mini" @click="importOfferclick('addData')"
                >Import Offer
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  import { getSourceList } from 'api/source/index';
  import { getOfferDetailNew } from 'api/offer/detail';
  import { insertOffer, handleCreativesAddBatch } from 'api/product/add';
  import { getReportSubruleList } from 'api/product/subrule';
  import { getPermissionAllList } from 'api/account/permission';
  import { myMixin } from '@/mixins/mixins.js';

  const defaulutData = {
    prodId: '',
    prod: '',
    offerName: '',
    description: '',
    category: '',
    previewLink: '',
    productType: '',
    conversionFlow: '',
    incentive: '0',
    trafficAllowed: '',
    trafficForbidden: '',
    osMinVersion: '',
    countries: '',
    platforms: '',
    payoutInfo: [
      {
        currency: 'USD',
        payout: '',
        payoutType: 'CPI',
        capDaily: '',
        revenue: '',
      },
    ],
    citys: '',
    regions: '',
    carriers: '',
    type: 'private',
    downloadType: '',
    sourceTrackingLink: '',
    sourceId: '',
    sourceOfferId: '',
    settleEvent: '',
    accountId: '',
    ruleId: '',
  };
  export default {
    mixins: [myMixin],
    data() {
      return {
        dialog: {
          creativiesInfoViewImg: {
            visible: false,
            img: '',
          },
        },
        loading: false,
        offerId: null,
        isCPI: true,
        file: null,
        addData: Object.assign({}, defaulutData),
        currencyOption: [
          {
            label: 'USD',
            value: 'USD',
          },
        ],
        asyncOption: [
          {
            label: 'YES',
            value: '1',
          },
          {
            label: 'NO',
            value: '0',
          },
        ],
        asyncRedirectOption: [
          {
            label: 'YES',
            value: '1',
          },
          {
            label: 'NO',
            value: '0',
          },
        ],
        incentiveOption: [
          {
            label: 'Incentive',
            value: '1',
          },
          {
            label: 'Non Incentive',
            value: '0',
          },
        ],
        importantOption: [
          {
            label: 'important',
            value: 'important',
          },
          {
            label: 'unimportant',
            value: 'unimportant',
          },
        ],
        productTypeOption: [
          {
            label: 'APP',
            value: 'APP',
          },
          {
            label: 'OPTION',
            value: 'OPTION',
          },
        ],
        payoutOption: [
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          // {
          //   label: 'CPM',
          //   value: 'CPM',
          // },
          // {
          //   label: 'CPC',
          //   value: 'CPC',
          // },
          // {
          //   label: 'CPE',
          //   value: 'CPE',
          // },
          // {
          //   label: 'CPS',
          //   value: 'CPS',
          // },
          // {
          //   label: 'SmartLink',
          //   value: 'SmartLink',
          // },
          {
            label: 'CPL',
            value: 'CPL',
          },
        ],
        platformsOption: [
          {
            label: 'IOS',
            value: 'IOS',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
        ],
        downloadTypeOption: [
          {
            label: 'GP',
            value: 'GP',
          },
          {
            label: 'ITUNES',
            value: 'ITUNES',
          },
          {
            label: 'Others',
            value: 'Others',
          },
        ],
        gidIdfaNeedsOption: [
          {
            label: 'YES',
            value: 'YES',
          },
          {
            label: 'NO',
            value: 'NO',
          },
        ],
        withEventOption: [
          {
            label: 'NO',
            value: '1',
          },
          {
            label: 'YES',
            value: '0',
          },
        ],
        sourceList: [],
        creativechecked: false,
        typeOption: [
          {
            label: 'Private',
            value: 'private',
          },
          {
            label: 'Public',
            value: 'public',
          },
        ],
        creativeComposeOption: [
          {
            label: '忽略布局',
            value: '0',
          },
          {
            label: '靠左',
            value: '1',
          },
          {
            label: '靠右',
            value: '2',
          },
          {
            label: '顶部',
            value: '3',
          },
          {
            label: '底部',
            value: '4',
          },
          {
            label: '左上角',
            value: '5',
          },
          {
            label: '左下角',
            value: '6',
          },
          {
            label: '右上角',
            value: '7',
          },
          {
            label: '右下角',
            value: '8',
          },
          {
            label: '正中',
            value: '9',
          },
        ],
        showDynamicOption: [
          {
            label: 'please choose',
            value: '',
          },
          {
            label: '否',
            value: '0',
          },
          {
            label: '是',
            value: '1',
          },
        ],
        showAdTypeOption: [
          {
            label: 'please choose',
            value: '',
          },
          {
            label: 'banner',
            value: '1',
          },
          {
            label: 'interest',
            value: '2',
          },
          {
            label: 'native',
            value: '3',
          },
          {
            label: 'native',
            value: '4',
          },
        ],
        showCloseOption: [
          {
            label: 'please choose',
            value: '',
          },
          {
            label: '无关闭按钮',
            value: '0',
          },
          {
            label: '左上角关闭',
            value: '1',
          },
          {
            label: '右上角关闭',
            value: '2',
          },
          {
            label: '左下角关闭',
            value: '3',
          },
          {
            label: '右下角关闭',
            value: '4',
          },
        ],
        option: {
          manager: [],
          rule: [],
        },
        creativiesInfo: [],
        rules: {
          prod: [
            {
              required: true,
              message: 'please add Pkg Name',
              trigger: 'blur',
            },
          ],
          offerName: [
            {
              required: true,
              message: 'please add offerName',
              trigger: 'blur',
            },
          ],
          previewLink: [
            {
              required: true,
              message: 'please add previewLink',
              trigger: 'blur',
            },
          ],
          countries: [
            {
              required: true,
              message: 'please add countries',
              trigger: 'blur',
            },
          ],
          // platforms: [
          // 	{
          // 		required: true,
          // 		message: 'please choice platform',
          // 		trigger: 'change'
          // 	}
          // ],
          // currency: [
          // 	{
          // 		required: true,
          // 		message: 'Please add currency',
          // 		trigger: ['blur', 'change']
          // 	}
          // ],
          sourceId: [
            {
              required: true,
              message: 'please choice sourceId',
              trigger: 'change',
            },
          ],
          sourceOfferId: [
            {
              required: true,
              message: 'please add sourceOfferId',
              trigger: 'blur',
            },
          ],
          sourceTrackingLink: [
            {
              required: true,
              message: 'please add sourceTrackingLink',
              trigger: 'blur',
            },
          ],
          type: [
            {
              required: true,
              message: 'please select Control type',
              trigger: 'blur',
            },
          ],
          // accountId: [
          //   {
          //     required: true,
          //     message: 'Please select offer manager',
          //     trigger: 'blur',
          //   },
          // ],
          ruleId: [
            {
              required: true,
              message: 'Please select sub rule',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    mounted() {
      this.getSourcesList();
      this.subruleList();
      this.managerList();
      let offer = sessionStorage.getItem('copyOffer');
      if (offer != undefined && offer != null) {
        let copy_offer = JSON.parse(sessionStorage.getItem('copyOffer'));
        console.log(copy_offer);
        if (Object.keys(copy_offer).length > 0) {
          this.copyOffer(copy_offer.offerId);
          sessionStorage.removeItem('copyOffer');
        }
      }
    },
    methods: {
      UpNumber(e) {
        // 输入框中只允许输入数字
        // e.target.value = e.target.value.replace(/[^\d]/g, '');
        // 输入框中只允许输入小数点和数字，小数点后只能跟一位
        e.target.value = e.target.value.match(/^\d*(\.?\d{0,4})/g)[0] || null;
      },
      // add payout info item
      plusPayoutInfo() {
        this.addData.payoutInfo.push({
          currency: 'USD',
          payout: '',
          eventName: '',
          capDaily: '',
          revenue: '',
        });
        console.log('++');
      },
      // del payout info item
      delPayoutInfo(index) {
        this.addData.payoutInfo.splice(index, 1);
      },
      getSourcesList() {
        this.loading = true;
        getSourceList().then((response) => {
          this.loading = false;
          if (response.code === 200) {
            this.sourceList = response.result
              .filter((item) => item.enable === 'ENABLE')
              .map((item) => ({
                value: item.id,
                label: `${item.id} - ${item.company}`,
              }));
          }
        });
      },
      platformsChange(val) {
        if (val == 'IOS') {
          this.addData.downloadType = 'ITUNES';
        } else if (val == 'ANDROID') {
          this.addData.downloadType = 'GP';
        }
      },
      importOfferclick(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // let _this = this;
            // const manager = this.option.manager.filter(function(e) {
            //   return e.accountId == _this.addData.accountId;
            // });
            insertOffer({
              // manager: manager[0].username,
              ...this.addData,
            }).then((response) => {
              if (response.code == 200) {
                this.addBatchImg(response.result);
              } else {
                this.$message.error(response.message);
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      addBatchImg(id) {
        var query = [];
        for (var i = 0; i < this.creativiesInfo.length; i++) {
          query.push({
            creativeUrl: this.creativiesInfo[i].creativeUrl,
            size: this.creativiesInfo[i].size,
            offerId: id,
          });
        }
        handleCreativesAddBatch(query).then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message({
              message: 'import success!',
              type: 'success',
            });
            // this.$router.push('/offer/top');
          } else {
            this.$message.error('creativies error!');
          }
        });
      },
      SelectPayout(val) {
        this.isCPI = val === 'CPI';
        this.$set(this.rules.prod[0], 'required', this.isCPI);
      },
      copyOffer(offerId) {
        //获取offer详情数据
        getOfferDetailNew(offerId).then((response) => {
          if (response.code === 200) {
            const result = response.result;
            if (result.controlType === 'api') {
              result.controlType = '';
            }
            this.addData = response.result;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      subruleList() {
        getReportSubruleList({
          status: 1,
        }).then((res) => {
          this.option.rule = res.result;
        });
      },
      managerList() {
        getPermissionAllList().then((res) => {
          this.option.manager = res.result;
          const uname = sessionStorage.getItem('userName');
          const ruleIdArr = res.result.filter(function(e) {
            return e.username == uname;
          });
          console.log(ruleIdArr);
          this.addData.accountId = ruleIdArr[0].accountId;
        });
      },
      // 上传之前
      beforeUpload(file) {
        const imgType = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!imgType) {
          this.$message.error('上传头像图片只能是 JPG / PNG 格式!');
          return false;
        }
        if (!isLt1M) {
          this.$message.error('上传头像图片大小不能超过1M!');
          return false;
        }
      },
      // 上传成功
      uploadSuccess(response) {
        console.log(response);
        if (response.result) {
          this.creativiesInfo.push({
            name: response.result.creativeUrl,
            url: response.result.creativeUrl,
            ...response.result,
          });
        } else {
          this.$message.error('上传失败！');
        }
      },
      // 删除图片
      handleRemove(file) {
        for (var i = 0; i < this.creativiesInfo.length; i++) {
          if (this.creativiesInfo[i].creativeUrl === file.response.result.creativeUrl) {
            this.creativiesInfo.splice(i, 1);
          }
        }
      },
      // 查看图片
      handlePictureCardPreview(file) {
        this.dialog.creativiesInfoViewImg.img = file.url;
        this.dialog.creativiesInfoViewImg.visible = true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .infotitle {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    margin-bottom: 10px;

    label {
      display: block;
    }
  }

  #upload_icon_list {
    margin: 10px 0 0 20px;
  }

  #upload_creative_list {
    margin: 10px 0 0 20px;
  }
</style>
